.reactFlagsSelect {
  padding:0!important;
}

.reactFlagsSelectWrapper {
  height: 40px;
}

.reactFlagsSelect span {
  justify-content: center;
}

.reactFlagsSelect ul {
  right: -15%;
  left: -15%;
}

.reactFlagsSelect button {
  border: none;
  padding: 0;
}

.reactFlagsSelect button::after {
  display: none!important;
}

.reactFlagsSelect ul {
  margin: 0!important;
  padding: 0!important;
  max-height: 80vh;
}


.reactFlagsSelect ul li {
  padding: 0 8px!important;
  display: block;
}

.loading {
  // background-color: rgba(224, 255, 255, 0.5);
  background-color: white;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  z-index: 1000000;
  height: 100vh;
}

.isbnInput {
  display: flex;
  align-items: center;
  position: relative;
  &:focus-visible {
    border: 0;
    outline: 0;
  }
  input {
    border-radius: 1rem;
    min-width: 4.5rem;
    padding-inline: 1rem;
    font-size: 2rem;
    border: 0.5px solid white;
    text-align: center;
    &:focus-visible {
      border: 0.5px solid white;
      outline: 0;
    }
  }

  .message {
    position: absolute;
    left: 1rem;
    bottom: -1.5rem;
    width: 250%;
  }
}

.quantityInput {
  display: flex;
  align-items: center;
  position: relative;
  &:focus-visible {
    border: 0;
    outline: 0;
  }
  input {
    border-radius: 1rem;
    min-width: 4.5rem;
    padding-inline: 1rem;
    font-size: 2rem;
    border: 0.5px solid white;
    text-align: center;
    &:focus-visible {
      border: 0.5px solid white;
      outline: 0;
    }
  }

  .message {
    position: absolute;
    left: 1rem;
    bottom: -1.5rem;
    width: 250%;
  }
}