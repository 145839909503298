@import '../../../common/styles/variables.scss';

.main {
  overflow-y: hidden;
  // overflow-wrap: anywhere;
  display: flex;
  flex-direction: column;
  padding-inline: 1rem;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
